import { IOrder } from "./order.interface";

export enum SseEventTypes {
  orderCreated = "order.created",
}

export interface ISseMessage {
  type: SseEventTypes;
  data: IOrder;
  id: string;
}
