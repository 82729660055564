import { HttpClient, } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
export function createTranslateLoader(http) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
