import { OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/userService/user.service';
import { OrdersService } from 'app/services/orders/orders.service';
var SidebarComponent = /** @class */ (function () {
    function SidebarComponent(orderService, userService, router, route, translate) {
        this.orderService = orderService;
        this.userService = userService;
        this.router = router;
        this.route = route;
        this.translate = translate;
        this.user = {};
    }
    SidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.menuItems = ROUTES.filter(function (menuItem) { return menuItem; });
        $.getScript('./assets/js/app-sidebar.js');
        this.userService.getUser().subscribe(function (user) {
            _this.user = user;
            // if (user.role == 'accounting') {
            //     this.menuItems = ROUTES2.filter(menuItem => menuItem);
            // } else {
            //     this.menuItems = ROUTES.filter(menuItem => menuItem);
            // }
            // this.orderService.getUndecidedCancelations().subscribe((orders) => {
            //     this.menuItems.find(item => item.title == 'Orders').badge = orders.length
            // })
            // setInterval(() => {
            //     this.orderService.getUndecidedCancelations().subscribe((orders) => {
            //         this.menuItems.find(item => item.title == 'Orders').badge = orders.length
            //     })
            // }, 5000)
        }, function (err) {
            console.log(err);
        });
    };
    // NGX Wizard - skip url change
    SidebarComponent.prototype.ngxWizardFunction = function (path) {
        if (path.indexOf('forms/ngx') !== -1)
            this.router.navigate(['forms/ngx/wizard'], { skipLocationChange: false });
    };
    return SidebarComponent;
}());
export { SidebarComponent };
