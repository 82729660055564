import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    /**
     *
     * @param routee
     * @param state
     *
     * token expired? lets try to refresh it, still expired?
     */
    AuthGuard.prototype.canActivate = function (routee, state) {
        var _this = this;
        console.log('can activate');
        return from(this.authService.isAuthenticatedAsync()).pipe(map(function (e) {
            console.log('yes', e);
            if (e === true) {
                return true;
            }
            else {
                _this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
                return false;
            }
        }));
        //return false;
        // const isAuthenticated = this.authService.isAuthenticatedAsync().then(() => {
        //   return true;
        // }).catch(() => {
        //   this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
        //   return false;
        // })
        // if (isAuthenticated){
        //   return true;
        // }else{
        //   this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
        //   return false;
        // }
    };
    return AuthGuard;
}());
export { AuthGuard };
