import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { AuthService } from 'app/shared/auth/auth.service';
var JwtInterceptor = /** @class */ (function () {
    function JwtInterceptor(auth) {
        this.auth = auth;
        this.refreshTokenInProgress = false;
        this.tokenRefreshedSource = new Subject();
        this.tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
    }
    JwtInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        // add authorization header with jwt token if available
        var token = JSON.parse(localStorage.getItem('currentUser'));
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: "JWT " + token.token
                }
            });
        }
        return next.handle(request).pipe(catchError(function (error, cought) {
            /**
             * 401 from server
             */
            if (error.status === 401) {
                console.log('unauthorized');
                // mycomment
                return _this.refreshToken().pipe(switchMap(function () {
                    var token = JSON.parse(localStorage.getItem('currentUser'));
                    if (token) {
                        request = request.clone({
                            setHeaders: {
                                Authorization: "JWT " + token.token
                            }
                        });
                    }
                    return next.handle(request).pipe(catchError(function (error) {
                        if (error.status === 401) {
                            _this.auth.logout();
                        }
                        return throwError(error);
                    }));
                }));
            }
            else {
                console.log(error);
                return throwError(error);
            }
        }));
    };
    JwtInterceptor.prototype.refreshToken = function () {
        var _this = this;
        if (this.refreshTokenInProgress) {
            return new Observable(function (observer) {
                _this.tokenRefreshed$.subscribe(function () {
                    observer.next();
                    observer.complete();
                });
            });
        }
        else {
            this.refreshTokenInProgress = true;
            return this.auth.refresh()
                .pipe(tap(function () {
                _this.refreshTokenInProgress = false;
                _this.tokenRefreshedSource.next();
            }));
        }
    };
    return JwtInterceptor;
}());
export { JwtInterceptor };
