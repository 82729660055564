import { OnInit } from '@angular/core';
var NotificationSidebarComponent = /** @class */ (function () {
    function NotificationSidebarComponent() {
    }
    NotificationSidebarComponent.prototype.ngOnInit = function () {
        // notification-sidebar JS File
        $.getScript('./assets/js/notification-sidebar.js');
    };
    return NotificationSidebarComponent;
}());
export { NotificationSidebarComponent };
