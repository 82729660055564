import { HttpClient } from '@angular/common/http';
import { Globals } from '../../globals';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../globals";
var UserService = /** @class */ (function () {
    function UserService(http, globals) {
        this.http = http;
        this.globals = globals;
    }
    UserService.prototype.getAll = function () {
        return this.http.get(this.globals.config.apiUrl + "/users");
    };
    UserService.prototype.getUser = function () {
        return this.http.get(this.globals.config.apiUrl + "/users/me");
    };
    UserService.prototype.createVendorUser = function (user) {
        return this.http.post(this.globals.config.apiUrl + "/users/createVendorUser", user);
    };
    UserService.prototype.getVendorUsers = function (vendorId) {
        return this.http.get(this.globals.config.apiUrl + "/users/bv/" + vendorId);
    };
    UserService.prototype.blockUser = function (id) {
        return this.http.post(this.globals.config.apiUrl + "/users/" + id + '/block', {});
    };
    UserService.prototype.unblockUser = function (id) {
        return this.http.post(this.globals.config.apiUrl + "/users/" + id + '/unblock', {});
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient), i0.inject(i2.Globals)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
