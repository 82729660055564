// globals.ts
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class Globals {

  config = {apiUrl: environment.apiUrl, apiV2Url: environment.apiV2Url}
  //config = { apiUrl: 'http://localhost:3001/api/v1' }
  // config = {apiUrl: 'http://192.168.0.197:3001/api/v1'}

  // config = {apiUrl: 'http://dev-env.ywrnmhvmbx.ap-southeast-1.elasticbeanstalk.com/api/v1'}
}
