import { isArray, isNil } from 'lodash-es';
import { toLocalIsoDate } from "./date.util";

export const requestQuery = (obj: any) => {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p) && !isNil(obj[p]) && !(typeof obj[p] === 'string' && !obj[p]) && typeof obj[p] !== 'object') {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
    if (isArray(obj[p])) {
      for (const elem of obj[p]) {
        str.push(`${p}[]=${encodeURIComponent(elem)}`);
      }
    } else if (typeof obj[p] === 'object') {
      for (const prop in obj[p]) {
        if (obj[p][prop] instanceof Date) {
          str.push(`${p}[${prop}]=${toLocalIsoDate(obj[p][prop])}`)
        } else {
          str.push(`${p}[${prop}]=${encodeURIComponent(obj[p][prop])}`)
        }
      }
    }
  }
  return str.join("&");
}
