import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../globals';
import { requestQuery } from "../../@utils/request-query.util";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../globals";
var OrdersService = /** @class */ (function () {
    function OrdersService(http, globals) {
        this.http = http;
        this.globals = globals;
        this.audioPlayStop = new BehaviorSubject(false);
        this.logoutClearOrders = new BehaviorSubject(false);
        this.ordersChange = new BehaviorSubject([]);
        this.ordersNotViewedCount = 0;
        this.newOrders = [];
    }
    OrdersService.prototype.getAll = function () {
        return this.http.get(this.globals.config.apiUrl + "/orders/vendor");
    };
    OrdersService.prototype.sseEmitTestOrder = function () {
        return this.http.post(this.globals.config.apiV2Url + "/vendor/orders/test/emit", {});
    };
    OrdersService.prototype.getUndecidedCancelations = function () {
        return this.http.get(this.globals.config.apiUrl + "/orders/cancelations");
    };
    OrdersService.prototype.reviewOrder = function (orderDbId, isApproved) {
        return this.http.put(this.globals.config.apiUrl + "/orders/" + orderDbId + "/cancelations/review", { result: isApproved });
    };
    OrdersService.prototype.get = function (id) {
        return this.http.get(this.globals.config.apiUrl + "/orders/" + id);
    };
    OrdersService.prototype.cancel = function (id) {
        return this.http.put(this.globals.config.apiUrl + "/orders/" + id + "/cancel", {});
    };
    OrdersService.prototype.getAllcash_register = function () {
        return this.http.get(this.globals.config.apiUrl + "/cash_register");
    };
    OrdersService.prototype.getcash_register = function (id) {
        return this.http.get(this.globals.config.apiUrl + "/cash_register/" + id);
    };
    OrdersService.prototype.cancelcash_register = function (id) {
        return this.http.delete(this.globals.config.apiUrl + "/cash_register/" + id);
    };
    OrdersService.prototype.sendmail = function (email) {
        return this.http.post(this.globals.config.apiUrl + "/admin/sendvendorinvoice", email);
    };
    OrdersService.prototype.addcash_register = function (cash_register) {
        return this.http.post(this.globals.config.apiUrl + "/cash_register", cash_register);
    };
    OrdersService.prototype.getOrderByDate = function (from, to) {
        return this.http.get(this.globals.config.apiUrl + "/orders/order-report/" + from + "/" + to);
    };
    OrdersService.prototype.getOrderBetweenDate = function (from, to) {
        return this.http.get(this.globals.config.apiUrl + "/orders/orders-by-date/" + from + "/" + to);
    };
    OrdersService.prototype.getAllDeliveredOrders = function () {
        return this.http.get(this.globals.config.apiUrl + "/orders/orders-by-date");
    };
    //   getOfferings(id: string) {
    //     return this.http.get<any>(`${this.globals.config.apiUrl}/vendors/${id}/offerings`);
    //   }
    //   add(vendor: any) {
    //     return this.http.post<any>(`${this.globals.config.apiUrl}/vendors`, vendor);
    //   }
    //   update(vendor: any) {
    //     return this.http.put<any>(`${this.globals.config.apiUrl}/vendors/${vendor._id}`, vendor);
    //   }
    //   delete(id: string) {
    //     return this.http.delete<any>(`${this.globals.config.apiUrl}/vendors/${id}`);
    //   }
    OrdersService.prototype.setViewOnDB = function (id) {
        return this.http.put(this.globals.config.apiUrl + "/orders/" + id + "/view-update", {});
    };
    OrdersService.prototype.updateStatus = function (orderDbId) {
        return this.http.put(this.globals.config.apiUrl + "/orders/" + orderDbId + "/updateOrder", {});
    };
    OrdersService.prototype.cancelOrder = function (orderDbId) {
        return this.http.put(this.globals.config.apiUrl + "/orders/" + orderDbId + "/cancel", {});
    };
    OrdersService.prototype.requestOrderCancelation = function (orderDbId) {
        return this.http.put(this.globals.config.apiUrl +
            "/orders/" +
            orderDbId +
            "/cancelations/request", {});
    };
    OrdersService.prototype.getPosInvoicePdf = function (orderId) {
        return this.http.get(this.globals.config.apiUrl + "/orders/get-pdf-invoice/" + orderId, { responseType: "arraybuffer" });
    };
    OrdersService.prototype.getOrdersForReport = function (params) {
        var query = requestQuery(params);
        return this.http.get(this.globals.config.apiV2Url + "/vendor/orders/report/index?" + query);
    };
    OrdersService.prototype.downloadOrdersReport = function (params) {
        var query = requestQuery(params);
        var headers = new HttpHeaders({
            'content-type': 'application/octet-stream',
        });
        return this.http.get(this.globals.config.apiV2Url + "/vendor/orders/report/file?" + query, { headers: headers, responseType: 'blob' });
    };
    OrdersService.ngInjectableDef = i0.defineInjectable({ factory: function OrdersService_Factory() { return new OrdersService(i0.inject(i1.HttpClient), i0.inject(i2.Globals)); }, token: OrdersService, providedIn: "root" });
    return OrdersService;
}());
export { OrdersService };
