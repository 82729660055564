import { Routes } from '@angular/router';
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { AuthGuard } from './shared/auth/auth-guard.service';
var ɵ0 = { title: 'full Views' }, ɵ1 = { title: 'content Views' };
var appRoutes = [
    {
        path: 'login',
        redirectTo: 'pages/login',
        pathMatch: 'full',
    },
    // {
    //   path: '',
    //   redirectTo: 'dashboard/dashboard1',
    //   pathMatch: 'full',
    // },
    { path: '', component: FullLayoutComponent, data: ɵ0, children: Full_ROUTES, canActivate: [AuthGuard] },
    { path: '', component: ContentLayoutComponent, data: ɵ1, children: CONTENT_ROUTES },
    { path: '**', redirectTo: '' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1 };
