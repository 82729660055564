import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { OrdersService } from "app/services/orders/orders.service";
import { Component, ViewContainerRef, OnInit } from "@angular/core";
import { AuthService } from "./shared/auth/auth.service";
import swal from "sweetalert2";
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { SseService } from "./services/sse.service";
import { SseEndpointsSuffixes } from "./@constants/sse-endpoints-suffixes.constant";
import { SseEventTypes } from "./@models/sse-events.model";
import { IOrder } from "./@models/order.interface";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  intervalId;
  orders = [];
  temp = [];
  firstTimeCall = true;
  audio;
  isPlaying = false;
  titleUpdateInterval;
  currentTitle;
  public subscriptions: Subscription[];

  constructor(
    private auth: AuthService,
    private ordersService: OrdersService,
    private titleService: Title,
    public toastr: ToastrService,
    private sseService: SseService,
    private router: Router
  ) {
    setInterval(() => {
      this.auth.refresh().subscribe();
    }, 900000);

    this.scheduleRefresh();
  }

  scheduleRefresh() {
    setTimeout(() => {
      this.auth.refresh().subscribe(() => {
        this.scheduleRefresh();
      });
    }, 12000);
  }

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      setTimeout(() => {
        console.log("-----------------swllllllll------------");
        swal("Welcome", "Welcome to the vendor app");
      }, 2000);
      this.initSubscriptions();
    } else {
      this.repeatCheck();
    }
    this.ordersService.audioPlayStop.subscribe((res) => {
      if (res && this.audio) {
        this.isPlaying = false;
        if (this.audio) this.audio.pause();
      }
    });
    this.ordersService.logoutClearOrders.subscribe((res) => {
      console.log("res", res);
      if (res) {
        this.firstTimeCall = true;
        this.temp = [];
        this.orders = [];
        this.isPlaying = false;
        if (this.audio) this.audio.pause();
      }
    });
  }

  initSubscriptions() {
    this.subscriptions = [
      this.sseService
        .createEventSource(SseEndpointsSuffixes.orders, [
          SseEventTypes.orderCreated,
        ])
        .subscribe((event) => {
          this.handleNewOrder(event.data);
        }),
    ];
  }

  repeatCheck() {
    if (this.auth.isAuthenticated()) {
      setTimeout(() => {
        console.log("-----------------swllllllll------------");
        swal("Welcome", "Welcome to the vendor app");
      }, 1000);
    } else {
      setTimeout(() => {
        this.repeatCheck();
      }, 2000);
    }
  }

  handleNewOrder(newOrder: IOrder) {
    this.orders.push(newOrder);
    this.setTitle();
    this.ordersService.ordersChange.next(this.orders);

    if (this.isPlaying && this.audio) {
      this.audio.pause();
      this.isPlaying = false;
    }

    this.playAudio();
  }

  //   refreshData(newOrder: IOrder) {
  //     this.orders.push(newOrder);
  //     this.setTitle();
  //     this.ordersService.ordersChange.next(this.orders);
  //     this.compareAndPlayAudio();
  //     this.checkOrderCancelation();
  //   }

  //   compareAndPlayAudio() {
  //     let tempIds = this.temp.map((i) => {
  //       return { _id: i._id, status: i.status };
  //     });
  //     // console.log("-----------tempIds", tempIds);
  //     let newIds = this.orders.map((i) => {
  //       return { _id: i._id, status: i.status };
  //     });
  //     // console.log("-----------newIds", newIds);
  //     let tempOrders = this.orders.filter(
  //       (i) => i.status !== "delivered" && i.status !== "canceled"
  //     );
  //     // console.log("-----------tempOrders", tempOrders);

  //     let arrayCompare = [];
  //     if (!this.firstTimeCall && tempOrders.length !== 0) {
  //       this.stopAudioWhenOrderStatusChange(tempIds, newIds);
  //       arrayCompare = this.arrayCompareAndReturnDifference(tempIds, newIds);
  //       if (!(arrayCompare.length === 0)) {
  //         console.log("arrayCompare", arrayCompare);
  //         if (!this.isPlaying) {
  //           localStorage.setItem(
  //             "LAST_ORDER_DETAILS",
  //             JSON.stringify(arrayCompare)
  //           );
  //           localStorage.setItem("LAST_AUDIO_PLAY", new Date().toString());
  //           this.playAudio();
  //         }
  //       }
  //     }
  //     this.ordersService.newOrders =
  //       this.ordersService.newOrders.concat(arrayCompare);
  //     // console.log('this.ordersService.newOrders', this.ordersService.newOrders);
  //     this.firstTimeCall = false;
  //   }

  checkOrderCancelation() {
    let oldIds = this.temp
      .map((i) => {
        return {
          _id: i._id,
          cancelationRequests: (i.cancelationRequests = i.cancelationRequests
            ? i.cancelationRequests
            : []),
        };
      })
      .filter(Boolean);
    let newIds = this.orders
      .map((i) => {
        return {
          _id: i._id,
          cancelationRequests: (i.cancelationRequests = i.cancelationRequests
            ? i.cancelationRequests
            : []),
        };
      })
      .filter(Boolean);
    // let oldIds = this.temp.map(i => { if (i.cancelationRequests && i.cancelationRequests.length) return { _id: i._id, cancelationRequests: i.cancelationRequests } }).filter(Boolean);
    // let newIds = this.orders.map(i => { if (i.cancelationRequests && i.cancelationRequests.length) return { _id: i._id, cancelationRequests: i.cancelationRequests } }).filter(Boolean);
    // console.log('-----------tempIds', oldIds);
    // console.log('------------newIds', newIds);

    for (let old of oldIds) {
      for (let ne of newIds) {
        if (old._id === ne._id) {
          if (old.cancelationRequests.length < ne.cancelationRequests.length) {
            // console.log('-old.cancelationRequests.length < ne.cancelationRequests.length', old.cancelationRequests.length < ne.cancelationRequests.length);
            // console.log('old._id', old._id);
            if (
              ne.cancelationRequests[ne.cancelationRequests.length - 1]
                .initiatorRole === "customer"
            ) {
              // console.log('------------------------------------------------------------------------------------------------------------------------------success popup');
              this.toastr
                .warning("You have a order cancelation request.", "", {
                  closeButton: true,
                  disableTimeOut: true,
                })
                .onTap.subscribe((res) => {
                  this.router.navigate([`/orders/details`, ne._id]);
                });
            }
          }
        }
      }
    }
    this.temp = this.orders;
  }

  setTitle() {
    // console.log('settitle---');
    let ordersNotViewedCount = this.orders.filter((i) => {
      return (
        i.vendorViewed !== undefined &&
        i.vendorViewed === false &&
        i.status !== "delivered" &&
        i.status !== "canceled"
      );
    }).length;
    this.ordersService.ordersNotViewedCount = ordersNotViewedCount;
    let title = document.title;
    let newTitle = "";
    if (this.ordersService.ordersNotViewedCount !== 0) {
      title = title.slice(title.lastIndexOf(")") + 1, title.length);
      newTitle = "(" + this.ordersService.ordersNotViewedCount + ") " + title;
      this.titleService.setTitle(newTitle);
      // console.log('-----------title-----1', this.ordersService.ordersNotViewedCount, newTitle);
    }
    if (this.ordersService.ordersNotViewedCount === 0) {
      newTitle = title.slice(title.lastIndexOf(")") + 1, title.length);
      this.titleService.setTitle(newTitle);
      // console.log('-----------title-----2', this.ordersService.ordersNotViewedCount, newTitle);
    }
    clearInterval(this.titleUpdateInterval);
    this.spalshingTitle();
  }

  spalshingTitle() {
    this.titleUpdateInterval = setInterval(() => {
      let title = document.title;
      let newTitle = "";
      if (this.currentTitle === "new") {
        title = title.slice(title.lastIndexOf(")") + 1, title.length);
        if (this.ordersService.ordersNotViewedCount) {
          newTitle =
            "(" + this.ordersService.ordersNotViewedCount + ") " + title;
        } else {
          newTitle = title.slice(title.lastIndexOf(")") + 1, title.length);
        }
        // console.log('newTitle new', newTitle);
        this.titleService.setTitle(newTitle);
        this.currentTitle = "old";
      } else {
        newTitle = title.slice(title.lastIndexOf(")") + 1, title.length);
        // console.log('newTitle old', newTitle);
        this.titleService.setTitle(newTitle);
        this.currentTitle = "new";
      }
      if (this.ordersService.ordersNotViewedCount === 0) {
        clearInterval(this.titleUpdateInterval);
      }
    }, 1000);
  }

  stopAudioWhenOrderStatusChange(a, b) {
    // checking status change from onhold to other status
    let newOrder = [];
    for (let i of a) {
      for (let j of b) {
        if (i._id === j._id) {
          if (i.status === "onhold" && j.status !== "onhold") {
            newOrder.push(j);
          }
        }
      }
    }
    if (newOrder.length) {
      if (this.audio) {
        this.audio.pause();
        this.isPlaying = false;
      }
    }
  }

  arrayCompareAndReturnDifference(a, b) {
    // compare and check new order is coming
    let onlyInA = a.filter(this.comparer(b));
    let onlyInB = b.filter(this.comparer(a));
    let result = onlyInA.concat(onlyInB);
    console.log("--------------------------------------result", result);
    return result;
  }

  comparer(otherArray) {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return other._id === current._id;
        }).length === 0
      );
    };
  }

  playAudio() {
    try {
      this.isPlaying = true;
      this.audio = new Audio();
      this.audio.src = "../../../assets/audio/noti-audio.mp3";
      this.audio.oncanplaythrough = (event) => {
        this.audio.loop = true;
        let playedPromise = this.audio.play();
        if (playedPromise) {
          playedPromise
            .catch((e) => {
              console.log(e);
              if (
                e.name === "NotAllowedError" ||
                e.name === "NotSupportedError"
              ) {
                alert("Audio Play error: " + e.name);
              }
            })
            .then(() => {
              console.log("playing sound !!!");
            });
        }
      };
      setTimeout(() => {
        this.audio.pause();
        this.isPlaying = false;
      }, 60000);
    } catch (err) {
      alert("AUDIO Play error");
    }
  }
}
