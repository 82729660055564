import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Globals } from "../globals";
import { userKey } from "app/@constants/main.constant";
import { EventSourcePolyfill } from "event-source-polyfill/src/eventsource.min.js";
import { ISseMessage, SseEventTypes } from "app/@models/sse-events.model";

@Injectable({
  providedIn: "root",
})
export class SseService {
  constructor(private globals: Globals) {}

  createEventSource(
    urlSuffix: string,
    eventTypes: SseEventTypes[]
  ): Observable<ISseMessage> {
    const sseUrl = `${this.globals.config.apiV2Url}/${urlSuffix}`;
    const token = JSON.parse(localStorage.getItem(userKey));
    if (token) {
      const eventSource = new EventSourcePolyfill(sseUrl, {
        headers: { Authorization: `JWT ${token.token}` },
      });

      return new Observable((observer) => {
        for (const eventType of eventTypes) {
          eventSource.addEventListener(eventType, (event) => {
            const messageData = JSON.parse(event.data);
            observer.next({ type: eventType, ...messageData });
          });
        }
        eventSource.onerror = (e) => {
          console.log(`Error with sse connection on ${sseUrl}`, e);
        };
        eventSource.onopen = (event) => {
          console.log(`Opened sse connection on ${sseUrl}`);
        };
      });
    }
  }
}
