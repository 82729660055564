import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { OrdersService } from "app/services/orders/orders.service";
import { OnInit } from "@angular/core";
import { AuthService } from "./shared/auth/auth.service";
import swal from "sweetalert2";
import { Title } from "@angular/platform-browser";
import { SseService } from "./services/sse.service";
import { SseEndpointsSuffixes } from "./@constants/sse-endpoints-suffixes.constant";
import { SseEventTypes } from "./@models/sse-events.model";
var AppComponent = /** @class */ (function () {
    function AppComponent(auth, ordersService, titleService, toastr, sseService, router) {
        var _this = this;
        this.auth = auth;
        this.ordersService = ordersService;
        this.titleService = titleService;
        this.toastr = toastr;
        this.sseService = sseService;
        this.router = router;
        this.orders = [];
        this.temp = [];
        this.firstTimeCall = true;
        this.isPlaying = false;
        setInterval(function () {
            _this.auth.refresh().subscribe();
        }, 900000);
        this.scheduleRefresh();
    }
    AppComponent.prototype.scheduleRefresh = function () {
        var _this = this;
        setTimeout(function () {
            _this.auth.refresh().subscribe(function () {
                _this.scheduleRefresh();
            });
        }, 12000);
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.auth.isAuthenticated()) {
            setTimeout(function () {
                console.log("-----------------swllllllll------------");
                swal("Welcome", "Welcome to the vendor app");
            }, 2000);
            this.initSubscriptions();
        }
        else {
            this.repeatCheck();
        }
        this.ordersService.audioPlayStop.subscribe(function (res) {
            if (res && _this.audio) {
                _this.isPlaying = false;
                if (_this.audio)
                    _this.audio.pause();
            }
        });
        this.ordersService.logoutClearOrders.subscribe(function (res) {
            console.log("res", res);
            if (res) {
                _this.firstTimeCall = true;
                _this.temp = [];
                _this.orders = [];
                _this.isPlaying = false;
                if (_this.audio)
                    _this.audio.pause();
            }
        });
    };
    AppComponent.prototype.initSubscriptions = function () {
        var _this = this;
        this.subscriptions = [
            this.sseService
                .createEventSource(SseEndpointsSuffixes.orders, [
                SseEventTypes.orderCreated,
            ])
                .subscribe(function (event) {
                _this.handleNewOrder(event.data);
            }),
        ];
    };
    AppComponent.prototype.repeatCheck = function () {
        var _this = this;
        if (this.auth.isAuthenticated()) {
            setTimeout(function () {
                console.log("-----------------swllllllll------------");
                swal("Welcome", "Welcome to the vendor app");
            }, 1000);
        }
        else {
            setTimeout(function () {
                _this.repeatCheck();
            }, 2000);
        }
    };
    AppComponent.prototype.handleNewOrder = function (newOrder) {
        this.orders.push(newOrder);
        this.setTitle();
        this.ordersService.ordersChange.next(this.orders);
        if (this.isPlaying && this.audio) {
            this.audio.pause();
            this.isPlaying = false;
        }
        this.playAudio();
    };
    //   refreshData(newOrder: IOrder) {
    //     this.orders.push(newOrder);
    //     this.setTitle();
    //     this.ordersService.ordersChange.next(this.orders);
    //     this.compareAndPlayAudio();
    //     this.checkOrderCancelation();
    //   }
    //   compareAndPlayAudio() {
    //     let tempIds = this.temp.map((i) => {
    //       return { _id: i._id, status: i.status };
    //     });
    //     // console.log("-----------tempIds", tempIds);
    //     let newIds = this.orders.map((i) => {
    //       return { _id: i._id, status: i.status };
    //     });
    //     // console.log("-----------newIds", newIds);
    //     let tempOrders = this.orders.filter(
    //       (i) => i.status !== "delivered" && i.status !== "canceled"
    //     );
    //     // console.log("-----------tempOrders", tempOrders);
    //     let arrayCompare = [];
    //     if (!this.firstTimeCall && tempOrders.length !== 0) {
    //       this.stopAudioWhenOrderStatusChange(tempIds, newIds);
    //       arrayCompare = this.arrayCompareAndReturnDifference(tempIds, newIds);
    //       if (!(arrayCompare.length === 0)) {
    //         console.log("arrayCompare", arrayCompare);
    //         if (!this.isPlaying) {
    //           localStorage.setItem(
    //             "LAST_ORDER_DETAILS",
    //             JSON.stringify(arrayCompare)
    //           );
    //           localStorage.setItem("LAST_AUDIO_PLAY", new Date().toString());
    //           this.playAudio();
    //         }
    //       }
    //     }
    //     this.ordersService.newOrders =
    //       this.ordersService.newOrders.concat(arrayCompare);
    //     // console.log('this.ordersService.newOrders', this.ordersService.newOrders);
    //     this.firstTimeCall = false;
    //   }
    AppComponent.prototype.checkOrderCancelation = function () {
        var _this = this;
        var oldIds = this.temp
            .map(function (i) {
            return {
                _id: i._id,
                cancelationRequests: (i.cancelationRequests = i.cancelationRequests
                    ? i.cancelationRequests
                    : []),
            };
        })
            .filter(Boolean);
        var newIds = this.orders
            .map(function (i) {
            return {
                _id: i._id,
                cancelationRequests: (i.cancelationRequests = i.cancelationRequests
                    ? i.cancelationRequests
                    : []),
            };
        })
            .filter(Boolean);
        // let oldIds = this.temp.map(i => { if (i.cancelationRequests && i.cancelationRequests.length) return { _id: i._id, cancelationRequests: i.cancelationRequests } }).filter(Boolean);
        // let newIds = this.orders.map(i => { if (i.cancelationRequests && i.cancelationRequests.length) return { _id: i._id, cancelationRequests: i.cancelationRequests } }).filter(Boolean);
        // console.log('-----------tempIds', oldIds);
        // console.log('------------newIds', newIds);
        for (var _i = 0, oldIds_1 = oldIds; _i < oldIds_1.length; _i++) {
            var old = oldIds_1[_i];
            var _loop_1 = function (ne) {
                if (old._id === ne._id) {
                    if (old.cancelationRequests.length < ne.cancelationRequests.length) {
                        // console.log('-old.cancelationRequests.length < ne.cancelationRequests.length', old.cancelationRequests.length < ne.cancelationRequests.length);
                        // console.log('old._id', old._id);
                        if (ne.cancelationRequests[ne.cancelationRequests.length - 1]
                            .initiatorRole === "customer") {
                            // console.log('------------------------------------------------------------------------------------------------------------------------------success popup');
                            this_1.toastr
                                .warning("You have a order cancelation request.", "", {
                                closeButton: true,
                                disableTimeOut: true,
                            })
                                .onTap.subscribe(function (res) {
                                _this.router.navigate(["/orders/details", ne._id]);
                            });
                        }
                    }
                }
            };
            var this_1 = this;
            for (var _a = 0, newIds_1 = newIds; _a < newIds_1.length; _a++) {
                var ne = newIds_1[_a];
                _loop_1(ne);
            }
        }
        this.temp = this.orders;
    };
    AppComponent.prototype.setTitle = function () {
        // console.log('settitle---');
        var ordersNotViewedCount = this.orders.filter(function (i) {
            return (i.vendorViewed !== undefined &&
                i.vendorViewed === false &&
                i.status !== "delivered" &&
                i.status !== "canceled");
        }).length;
        this.ordersService.ordersNotViewedCount = ordersNotViewedCount;
        var title = document.title;
        var newTitle = "";
        if (this.ordersService.ordersNotViewedCount !== 0) {
            title = title.slice(title.lastIndexOf(")") + 1, title.length);
            newTitle = "(" + this.ordersService.ordersNotViewedCount + ") " + title;
            this.titleService.setTitle(newTitle);
            // console.log('-----------title-----1', this.ordersService.ordersNotViewedCount, newTitle);
        }
        if (this.ordersService.ordersNotViewedCount === 0) {
            newTitle = title.slice(title.lastIndexOf(")") + 1, title.length);
            this.titleService.setTitle(newTitle);
            // console.log('-----------title-----2', this.ordersService.ordersNotViewedCount, newTitle);
        }
        clearInterval(this.titleUpdateInterval);
        this.spalshingTitle();
    };
    AppComponent.prototype.spalshingTitle = function () {
        var _this = this;
        this.titleUpdateInterval = setInterval(function () {
            var title = document.title;
            var newTitle = "";
            if (_this.currentTitle === "new") {
                title = title.slice(title.lastIndexOf(")") + 1, title.length);
                if (_this.ordersService.ordersNotViewedCount) {
                    newTitle =
                        "(" + _this.ordersService.ordersNotViewedCount + ") " + title;
                }
                else {
                    newTitle = title.slice(title.lastIndexOf(")") + 1, title.length);
                }
                // console.log('newTitle new', newTitle);
                _this.titleService.setTitle(newTitle);
                _this.currentTitle = "old";
            }
            else {
                newTitle = title.slice(title.lastIndexOf(")") + 1, title.length);
                // console.log('newTitle old', newTitle);
                _this.titleService.setTitle(newTitle);
                _this.currentTitle = "new";
            }
            if (_this.ordersService.ordersNotViewedCount === 0) {
                clearInterval(_this.titleUpdateInterval);
            }
        }, 1000);
    };
    AppComponent.prototype.stopAudioWhenOrderStatusChange = function (a, b) {
        // checking status change from onhold to other status
        var newOrder = [];
        for (var _i = 0, a_1 = a; _i < a_1.length; _i++) {
            var i = a_1[_i];
            for (var _a = 0, b_1 = b; _a < b_1.length; _a++) {
                var j = b_1[_a];
                if (i._id === j._id) {
                    if (i.status === "onhold" && j.status !== "onhold") {
                        newOrder.push(j);
                    }
                }
            }
        }
        if (newOrder.length) {
            if (this.audio) {
                this.audio.pause();
                this.isPlaying = false;
            }
        }
    };
    AppComponent.prototype.arrayCompareAndReturnDifference = function (a, b) {
        // compare and check new order is coming
        var onlyInA = a.filter(this.comparer(b));
        var onlyInB = b.filter(this.comparer(a));
        var result = onlyInA.concat(onlyInB);
        console.log("--------------------------------------result", result);
        return result;
    };
    AppComponent.prototype.comparer = function (otherArray) {
        return function (current) {
            return (otherArray.filter(function (other) {
                return other._id === current._id;
            }).length === 0);
        };
    };
    AppComponent.prototype.playAudio = function () {
        var _this = this;
        try {
            this.isPlaying = true;
            this.audio = new Audio();
            this.audio.src = "../../../assets/audio/noti-audio.mp3";
            this.audio.oncanplaythrough = function (event) {
                _this.audio.loop = true;
                var playedPromise = _this.audio.play();
                if (playedPromise) {
                    playedPromise
                        .catch(function (e) {
                        console.log(e);
                        if (e.name === "NotAllowedError" ||
                            e.name === "NotSupportedError") {
                            alert("Audio Play error: " + e.name);
                        }
                    })
                        .then(function () {
                        console.log("playing sound !!!");
                    });
                }
            };
            setTimeout(function () {
                _this.audio.pause();
                _this.isPlaying = false;
            }, 60000);
        }
        catch (err) {
            alert("AUDIO Play error");
        }
    };
    return AppComponent;
}());
export { AppComponent };
