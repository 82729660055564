/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap/collapse/collapse";
import * as i3 from "@ng-bootstrap/ng-bootstrap/dropdown/dropdown";
import * as i4 from "@ng-bootstrap/ng-bootstrap/dropdown/dropdown-config";
import * as i5 from "@angular/common";
import * as i6 from "./navbar.component";
import * as i7 from "../auth/auth.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../services/orders/orders.service";
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
export function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 32, "nav", [["class", "header-navbar navbar navbar-expand-lg navbar-light bg-faded"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 31, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "navbar-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "button", [["class", "navbar-toggle d-lg-none float-left"], ["data-toggle", "collapse"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Toggle navigation"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "icon-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [["class", "d-lg-none navbar-right navbar-collapse-toggle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["aria-controls", "navbarSupportedContent"], ["class", "open-navbar-container"]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isCollapsed = !_co.isCollapsed) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "ft-more-vertical"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 20, "div", [["class", "navbar-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 19, "div", [["class", "collapse navbar-collapse"], ["id", "navbarSupportedContent"]], [[2, "collapse", null], [2, "show", null]], null, null, null, null)), i1.ɵdid(14, 16384, null, 0, i2.NgbCollapse, [], { collapsed: [0, "collapsed"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 17, "ul", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 16, "li", [["class", "nav-item"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(17, 212992, null, 2, i3.NgbDropdown, [i1.ChangeDetectorRef, i4.NgbDropdownConfig, i5.DOCUMENT, i1.NgZone], { placement: [0, "placement"] }, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i1.ɵeld(20, 0, null, null, 5, "a", [["aria-haspopup", "true"], ["class", "nav-link position-relative dropdown-toggle"], ["id", "dropdownBasic3"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 16384, null, 0, i3.NgbDropdownToggle, [i3.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[2, 4]], i3.NgbDropdownAnchor, null, [i3.NgbDropdownToggle]), (_l()(), i1.ɵeld(23, 0, null, null, 0, "i", [["class", "ft-user font-medium-3 blue-grey darken-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "p", [["class", "d-none"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["User Settings"])), (_l()(), i1.ɵeld(26, 0, null, null, 6, "div", [["aria-labelledby", "dropdownBasic3"], ["class", "text-left"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], null, null, null, null)), i1.ɵdid(27, 16384, [[1, 4]], 0, i3.NgbDropdownMenu, [i3.NgbDropdown, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(28, 0, null, null, 0, "div", [["class", "dropdown-divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 3, "a", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 0, "i", [["class", "ft-power mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isCollapsed; _ck(_v, 14, 0, currVal_3); var currVal_5 = _co.placement; _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isCollapsed; _ck(_v, 10, 0, currVal_0); var currVal_1 = true; var currVal_2 = !i1.ɵnov(_v, 14).collapsed; _ck(_v, 13, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 17).isOpen(); _ck(_v, 16, 0, currVal_4); var currVal_6 = i1.ɵnov(_v, 21).dropdown.isOpen(); _ck(_v, 20, 0, currVal_6); var currVal_7 = true; var currVal_8 = i1.ɵnov(_v, 27).dropdown.isOpen(); var currVal_9 = i1.ɵnov(_v, 27).placement; _ck(_v, 26, 0, currVal_7, currVal_8, currVal_9); }); }
export function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 49152, null, 0, i6.NavbarComponent, [i7.AuthService, i8.TranslateService, i9.OrdersService], null, null)], null, null); }
var NavbarComponentNgFactory = i1.ɵccf("app-navbar", i6.NavbarComponent, View_NavbarComponent_Host_0, {}, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
