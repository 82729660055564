import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../globals';
import { requestQuery } from "../../@utils/request-query.util";
import { IOrdersForReportRequestParams } from "../../@models/orders-request-params.interface";
import { BehaviorSubject, Observable } from "rxjs";
import { OrdersReportResponseDto } from "../../@models/orders-report-response.dto";

@Injectable({
  providedIn: "root",
})
export class OrdersService {
  public audioPlayStop = new BehaviorSubject(false);
  public logoutClearOrders = new BehaviorSubject(false);
  public ordersChange = new BehaviorSubject([]);
  public ordersNotViewedCount = 0;
  public newOrders = [];
  constructor(private http: HttpClient, private globals: Globals) {}

  getAll() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/orders/vendor`);
  }

  sseEmitTestOrder() {
    return this.http.post<any>(
      `${this.globals.config.apiV2Url}/vendor/orders/test/emit`,
      {}
    );
  }

  getUndecidedCancelations() {
    return this.http.get<any>(
      `${this.globals.config.apiUrl}/orders/cancelations`
    );
  }

  reviewOrder(orderDbId, isApproved) {
    return this.http.put<any>(
      `${this.globals.config.apiUrl}/orders/${orderDbId}/cancelations/review`,
      { result: isApproved }
    );
  }

  get(id: string) {
    return this.http.get<any>(`${this.globals.config.apiUrl}/orders/${id}`);
  }

  cancel(id: string) {
    return this.http.put<any>(
      `${this.globals.config.apiUrl}/orders/${id}/cancel`,
      {}
    );
  }
  getAllcash_register() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/cash_register`);
  }

  getcash_register(id: string) {
    return this.http.get<any>(
      `${this.globals.config.apiUrl}/cash_register/${id}`
    );
  }

  cancelcash_register(id: string) {
    return this.http.delete<any>(
      `${this.globals.config.apiUrl}/cash_register/${id}`
    );
  }
  sendmail(email) {
    return this.http.post<any>(
      `${this.globals.config.apiUrl}/admin/sendvendorinvoice`,
      email
    );
  }
  addcash_register(cash_register: any) {
    return this.http.post<any>(
      `${this.globals.config.apiUrl}/cash_register`,
      cash_register
    );
  }

  getOrderByDate(from, to) {
    return this.http.get<any>(
      `${this.globals.config.apiUrl}/orders/order-report/${from}/${to}`
    );
  }
  getOrderBetweenDate(from, to) {
    return this.http.get<any>(
      `${this.globals.config.apiUrl}/orders/orders-by-date/${from}/${to}`
    );
  }
  getAllDeliveredOrders() {
    return this.http.get<any>(
      `${this.globals.config.apiUrl}/orders/orders-by-date`
    );
  }
  //   getOfferings(id: string) {
  //     return this.http.get<any>(`${this.globals.config.apiUrl}/vendors/${id}/offerings`);
  //   }

  //   add(vendor: any) {
  //     return this.http.post<any>(`${this.globals.config.apiUrl}/vendors`, vendor);
  //   }

  //   update(vendor: any) {
  //     return this.http.put<any>(`${this.globals.config.apiUrl}/vendors/${vendor._id}`, vendor);
  //   }

  //   delete(id: string) {
  //     return this.http.delete<any>(`${this.globals.config.apiUrl}/vendors/${id}`);
  //   }
  setViewOnDB(id: string) {
    return this.http.put<any>(
      `${this.globals.config.apiUrl}/orders/${id}/view-update`,
      {}
    );
  }
  updateStatus(orderDbId: string) {
    return this.http.put<any>(
      this.globals.config.apiUrl + "/orders/" + orderDbId + "/updateOrder",
      {}
    );
  }
  cancelOrder(orderDbId: string) {
    return this.http.put(
      this.globals.config.apiUrl + "/orders/" + orderDbId + "/cancel",
      {}
    );
  }
  requestOrderCancelation(orderDbId: string): any {
    return this.http.put(
      this.globals.config.apiUrl +
        "/orders/" +
        orderDbId +
        "/cancelations/request",
      {}
    );
  }
  getPosInvoicePdf(orderId: string) {
    return this.http.get(
      `${this.globals.config.apiUrl}/orders/get-pdf-invoice/${orderId}`,
      { responseType: "arraybuffer" }
    );
  }

  getOrdersForReport(params: IOrdersForReportRequestParams): Observable<OrdersReportResponseDto> {
    let query = requestQuery(params);
    return this.http.get<OrdersReportResponseDto>(`${this.globals.config.apiV2Url}/vendor/orders/report/index?${query}`);
  }

  downloadOrdersReport(params: IOrdersForReportRequestParams): Observable<Blob> {
    let query = requestQuery(params);
    const headers = new HttpHeaders({
      'content-type': 'application/octet-stream',
    });
    return this.http.get(`${this.globals.config.apiV2Url}/vendor/orders/report/file?${query}`, { headers: headers, responseType: 'blob' });
  }

}
