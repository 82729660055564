import { RouteInfo } from "./sidebar.metadata";

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard/dashboard1",
    title: "Dashboard",
    icon: "ft-home",
    class: "",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [],
  },

  // {
  //     path: '', title: 'Vendors', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
  //     submenu: [
  //         { path: '/vendors', title: 'All Vendors', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         { path: '/vendors/ordering', title: 'Vendors Order', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         { path: '/offeringcategories', title: 'Offering Categories', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         { path: '/vendors/registrations', title: 'Registrations', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     ]
  // },
  {
    path: "/orders",
    title: "Orders",
    icon: "ft-shopping-cart",
    class: "",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [
      // { path: '/orders', title: 'All Orders', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      // { path: '/   offeringcategories', title: 'Offering Categories', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ] },
    ],
  },
  // {
  //     path: '/calendar', title: 'My Calendar', icon: 'fa fa-calendar', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
  // },
  {
    path: "/capacity",
    title: "Capacity",
    icon: "fa fa-sitemap",
    class: "",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/my-service-timing",
    title: "My Service Timimg",
    icon: "ft-clock",
    class: "",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/vendorofferings",
    title: "My Menu",
    icon: "ft-list",
    class: "",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/cust-review",
    title: "Customer Review",
    icon: "ft-star",
    class: "",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/promocodes",
    title: "Promo Codes",
    icon: "ft-tag",
    class: "",
    badge: "",
    badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
    isExternalLink: false,
    submenu: [],
  },

  // {
  //     path: '/searchcategorysections', title: 'Search Categories', icon: 'ft-info', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
  //         {
  //             path: '/searchcategorysections/index', title: 'All', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [

  //             ]
  //         },
  //         {
  //             path: '/searchcategorysections/create', title: 'Create', icon: 'ft-plus', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [

  //             ]
  //         }
  //     ]
  // },

  // {
  //     path: 'users', title: 'Users', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
  //         { path: '/tables/extended', title: 'All Users', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     ]
  // },

  // {
  //     path: 'https://dashboard.tawk.to/login', title: 'Customer Support', icon: 'ft-external-link', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: []
  // },

  // {
  //     path: 'https://onesignal.com/apps/781a5140-c36c-4c29-86f5-d3c3c683a948', title: 'Push Notifications Dashboard', icon: 'ft-external-link', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: []
  // },

  // {
  //     path: 'https://play.google.com/store/apps/details?id=com.carts.cartsapp', title: 'Google Play', icon: 'ft-external-link', class: '', badge: '', badgeClass: '', isExternalLink: true, submenu: []
  // }
];
export const ROUTES2: RouteInfo[] = [
  //  { path: '/dashboard/dashboard2', title: 'Accountion Dashboard', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

  //{ path: '/taskboard', title: 'Accounting', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  {
    path: "/forms/basic",
    title: "Profits",
    icon: "",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  {
    path: "/forms/horizontal",
    title: " Expenses",
    icon: "",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  // { path: '/forms/hidden-labels', title: 'Net', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  {
    path: "/charts/chartist",
    title: "Net",
    icon: "",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
  },
  //      { path: '/forms/form-actions', title: 'Trail Balance', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //   { path: '/forms/bordered', title: 'Income Sheet', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  // { path: '/forms/striped-rows', title: 'Balance Sheet', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
];
