import { OrdersService } from 'app/services/orders/orders.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "../../shared/auth/auth.service";
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent(auth, translate, ordersService) {
        this.auth = auth;
        this.translate = translate;
        this.ordersService = ordersService;
        this.currentLang = 'en';
        this.toggleClass = 'ft-maximize';
        this.placement = 'bottom-right';
        this.isCollapsed = true;
        var browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
    }
    NavbarComponent.prototype.logout = function () {
        this.ordersService.logoutClearOrders.next(true);
        this.auth.logout();
    };
    NavbarComponent.prototype.ChangeLanguage = function (language) {
        this.translate.use(language);
    };
    NavbarComponent.prototype.ToggleClass = function () {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        }
        else
            this.toggleClass = 'ft-maximize';
    };
    return NavbarComponent;
}());
export { NavbarComponent };
